import React, { forwardRef } from 'react';

import { MenuItemBase } from './menu-item';
import { MenuListItem } from './menu-list-item';
import type { MenuItemCommonProps } from './types';

/**
 * We intentionally do not support the `isSelected` prop (which other menu item components support) because
 * `MenuButtonItem`s do not correspond to a "page", so can't be navigated to and become in a selected state.
 */
export type MenuButtonItemProps = MenuItemCommonProps & {
	isDisabled?: boolean;
};

/**
 * __MenuButtonItem__
 *
 * A menu item button. It should be used within a `ul`.
 */
export const MenuButtonItem = forwardRef<HTMLButtonElement, MenuButtonItemProps>(
	(
		{
			testId,
			actions,
			children,
			description,
			elemAfter,
			isDisabled,
			elemBefore,
			actionsOnHover,
			onClick,
			interactionName,
			isContentTooltipDisabled,
		},
		forwardedRef,
	) => (
		<MenuListItem>
			<MenuItemBase
				testId={testId}
				description={description}
				actions={actions}
				elemAfter={elemAfter}
				elemBefore={elemBefore}
				isDisabled={isDisabled}
				actionsOnHover={actionsOnHover}
				onClick={onClick}
				ref={forwardedRef}
				interactionName={interactionName}
				isContentTooltipDisabled={isContentTooltipDisabled}
			>
				{children}
			</MenuItemBase>
		</MenuListItem>
	),
);
